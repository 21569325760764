import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import Button from "../components/button"
import BeerTile from "../components/beerTile"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      beerImg: file(relativePath: { eq: "background/Beer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shopImg: file(relativePath: { eq: "background/Shop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brewImg: file(relativePath: { eq: "background/Brewery.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="The H" />
      <Hero />
      <div class="container mx-auto px-4 py-10 md:px-20 lg:px-40 mb-10">
        <h1
          id="the-h-title"
          className="font-header font-black text-center text-text text-2xl uppercase mb-4"
        >
          The H
        </h1>
        <p className="text-text text-center">
          „Die Welt ist kompliziert? Das Bier bleibt einfach!“ Getreu nach
          diesem Slogan brauen wir seit 2019, das beste Getränk der Welt, mit
          Ecken und Kanten, so wie es sein soll. Da auch Bier am besten mit
          gutem Essen funktioniert, bieten wir Degustations Menüs, von unserem
          verrückten Koch jedes Mal neu zusammengestellt an. Also kommt vorbei!
        </p>
      </div>
      <div class="h-auto sm:h-auto md:h-64 lg:h-64 xl:h-64 bg-grayBg ">
        <div class="container mx-auto flex flex-wrap">
          <div
            class="relative w-full sm:w-1/2 bg-gray-400 sm:inset-0"
            style={{ height: 450, top: -60 }}
          >
            <BeerTile />
          </div>
          <div class="w-full sm:w-1/2 bg-grayBg py-4 px-16 sm:px-2 md:px-2 lg:px-16 xl:px-16 h-auto md:h-64 lg:h-64 xl:h-64">
            <h2 className="font-header font-black text-white text-xl pb-2 uppercase">
              Das Original
            </h2>
            <p className="text-white pb-4">
              Ein bayerisches Helles mit 11,5% Stammwürze, mit zitrusaromen,
              welches ganz ohne orangenschale oder anderen schnick schnack aus
              kommt. Passt perfekt zu allen Anlässen schmeckt aber am besten zum
              Feierabend.
            </p>
            <span className="visible sm:visible md:invisible lg:visible xl:visible">
              <Button color="primary" to="/">
                Jetzt Einkaufen
              </Button>
            </span>
          </div>
        </div>
      </div>
      <div className="pt-10 md:pt-32 lg:pt-32 xl:pt-32">
        <h2 className="font-header font-black text-center text-text text-2xl uppercase mb-4">
          Mehr von uns
        </h2>
        <div className="flex flex-wrap -mx-1">
          <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 pr-1">
            <div className="relative h-64 overflow-hidden flex items-center justify-center ">
              <Link className="absolute text-white inset-x-auto center z-10 border-white border-t-4 border-b-4 text-2xl px-2 font-black font-header">
                Bier
              </Link>
              <Img
                fluid={data.beerImg.childImageSharp.fluid}
                className="w-full h-full"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 pr-1">
            <div className="relative h-64 overflow-hidden flex items-center justify-center ">
              <Link className="absolute text-white inset-x-auto center z-10 border-white border-t-4 border-b-4 text-2xl px-2 font-black font-header">
                Shop
              </Link>
              <Img
                fluid={data.shopImg.childImageSharp.fluid}
                className="w-full h-full"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
            <div className="relative h-64 overflow-hidden flex items-center justify-center ">
              <Link className="absolute text-white inset-x-auto center z-10 border-white border-t-4 border-b-4 text-2xl px-2 font-black font-header">
                Brauerei
              </Link>
              <Img
                fluid={data.brewImg.childImageSharp.fluid}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
