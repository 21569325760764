import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import background from "../assets/images/background/nice_snow.png"

const BeerTile = () => {
  const data = useStaticQuery(graphql`
    query {
      beer: file(relativePath: { eq: "beer/HellesCan.png" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className="bg-cover bg-center flex h-full items-center flex-col py-6"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <h3 className="font-header font-black text-center text-text uppercase mb-4">
        Helles
      </h3>
      <Img fluid={data.beer.childImageSharp.fluid} className="w-40" />
    </div>
  )
}

export default BeerTile
