import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Particles from "react-particles-js"

import Button from "../button"

import "./hero.scss"

import particlesConfig from "../../config/particlesConfig"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "MainLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="hero">
      <div className="hero-content">
        <Img fluid={data.logo.childImageSharp.fluid} className="hero-image" />
        <Button to="/#the-h-title">Erfahre Mehr</Button>
      </div>
      <Particles
        params={particlesConfig}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
    </section>
  )
}

export default Hero
