import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Button = ({ children, to, color = "white" }) => (
  <Link
    className={`bg-transparent uppercase font-header text-${color} font-black py-2 px-4 border-4 border-${color}`}
    to={to}
    style={{ whiteSpace: "nowrap" }}
  >
    {children}
  </Link>
)

Button.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Button
